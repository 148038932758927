import {useState, useEffect, useCallback} from 'react';

import {toggleDatetime, selectDateTime} from 'context/customer/actions';
import {fetchDatesOpen, fetchAvailableSlots} from 'api/customer';
import {errorInit} from 'utils/error';

const useCustomerDatetime = ({
  useDispatchHook,
  useStateHook,
  onSubmitPromiseCallback,
  ...props
}) => {
  const [loadingDate, setLoadingDate] = useState(false);
  const [errorDate, setErrorDate] = useState(errorInit);
  const [loadingSlot, setLoadingSlot] = useState(false);
  const [errorSlot, setErrorSlot] = useState(errorInit);
  const state = useStateHook();
  const {
    order: {storeId},
    datetime: {dateValue, timeValue}
  } = state;
  const [datesOpen, setDatesOpen] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [date, setDate] = useState(dateValue);
  const [slot, setSlot] = useState(timeValue);
  const dispatch = useDispatchHook();

  const getRequestStoreId = useCallback(() => {
    if (storeId) return storeId;
    if (!storeId && props?.storeId) {
      return props?.storeId;
    }
  }, [props, storeId]);

  const requestStoreId = getRequestStoreId();

  useEffect(() => {
    async function getDatesOpen() {
      setLoadingDate(true);
      try {
        const dates = await fetchDatesOpen({
          storeId: requestStoreId,
          bookingType: props?.bookingType
        });
        setDatesOpen(dates);
      } catch (error) {
        setErrorDate({show: true, code: error.code});
      } finally {
        setLoadingDate(false);
      }
    }

    if (requestStoreId) {
      getDatesOpen();
    }
  }, [props.bookingType, requestStoreId]);

  useEffect(() => {
    async function getAvailableSlots() {
      setLoadingSlot(true);
      try {
        const response = await fetchAvailableSlots({
          storeId: requestStoreId,
          date,
          bookingType: props?.bookingType
        });
        setAvailableSlots(response);
      } catch (error) {
        setErrorSlot({show: true, code: error.code});
      } finally {
        setLoadingSlot(false);
      }
    }

    if (date && requestStoreId) getAvailableSlots();
  }, [date, props.bookingType, requestStoreId, storeId]);

  const onClose = () => {
    toggleDatetime({dispatch});
  };

  const onClickDate = async (dateOpen) => {
    setDate(dateOpen);
    setSlot(null);
  };

  const onSubmit = async () => {
    selectDateTime({dispatch}, {dateValue: date, timeValue: slot});

    if (onSubmitPromiseCallback) {
      return await onSubmitPromiseCallback({storeId, dateTimeState: {date, slot}});
    }
  };

  return {
    loadingDate,
    errorDate,
    loadingSlot,
    errorSlot,
    datesOpen,
    availableSlots,
    date,
    slot,
    onClose,
    onClickDate,
    setSlot,
    onSubmit
  };
};

export default useCustomerDatetime;
