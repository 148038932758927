import React, {Fragment, lazy, Suspense} from 'react';
import PropTypes from 'prop-types';
import {getBrandName} from 'utils/brand';

const PMMTheme = lazy(() => import('./PMM'));
const PublicTheme = lazy(() => import('./Public'));
const MediamarktTheme = lazy(() => import('./Mediamarkt'));

const Theme = () => {
  if (getBrandName() === 'public') {
    return <PublicTheme />;
  }

  if (getBrandName() === 'mediamarkt') {
    return <MediamarktTheme />;
  }

  if (getBrandName() === 'agent') {
    return <PMMTheme />;
  }

  return <PMMTheme />;
};

const ThemeSelector = ({children}) => (
  <Fragment>
    <Suspense fallback={<Fragment />}>
      <Theme />
      {children}
    </Suspense>
  </Fragment>
);

ThemeSelector.propTypes = {
  children: PropTypes.node.isRequired
};

export default ThemeSelector;
