import {
  APPOINTMENT_INITIALIZE,
  SEARCH_APPOINTMENTS_SUCCESS,
  TOGGLE_QUERIED,
  FETCH_BOOKED_APPOINTMENTS_SUCCESS,
  GET_APPOINTMENT_SUCCESS,
  GET_COMMENTS_SUCCESS,
  TOGGLE_IN_STORE
} from 'context/agent/constants/ActionTypes';
import createReducer from 'utils/createReducer';
import {isClickInShop} from 'utils/brand';

const initialState = {
  brand: 'public',
  lang: 'el',
  queried: false,
  storeId: 1,
  searchResults: [],
  appointments: [],
  customer: null,
  order: {
    storeId: null
  },
  bookedListFilter: 'ALL',
  inStore: isClickInShop
};

const reducer = createReducer(initialState, {
  [APPOINTMENT_INITIALIZE]: () => initialState,
  [TOGGLE_QUERIED]: (state, payload) => ({...state, queried: payload}),
  [SEARCH_APPOINTMENTS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      searchResults: payload,
      queried: true
    };
  },
  [FETCH_BOOKED_APPOINTMENTS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      appointments: payload
    };
  },
  [GET_COMMENTS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      customer: {
        ...state.customer,
        comments: payload
      }
    };
  },
  [GET_APPOINTMENT_SUCCESS]: (state, payload) => {
    return {
      ...state,
      customer: payload
    };
  },
  SET_STORE_ID: (state, payload) => ({
    ...state,
    orderId: payload.orderId,
    storeId: payload.storeId
  }),
  SET_FILTER: (state, payload) => ({
    ...state,
    bookedListFilter: payload
  }),
  CLEAR_FILTER: (state, payload) => ({
    ...state,
    bookedListFilter: ''
  }),
  [TOGGLE_IN_STORE]: (state, payload) => ({...state, inStore: payload})
});

export {reducer, initialState};
