import React from 'react';

const SectorExpTitle = ({title, onClick}) => {
  return (
    <div className="font-weight-semibold text-primary-main d-flex align-items-center justify-content-between">
      {title}
      <i className="action-cirlce fa fa-times fa-lg cursor-pointer" onClick={onClick} />
    </div>
  );
};

export default SectorExpTitle;
