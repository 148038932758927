import React, {useMemo, useEffect} from 'react';
import {useCustomerDispatch, useCustomerState} from 'context/customer/customer-context';
import SectorCard from 'components/Customer/SectorCard';
import SectorTitle from 'components/Customer/Sector/SectorTitle';
import {toggleStore} from 'context/customer/clickInShop/actions';
import {parseStore} from 'utils/pojos';

const Store = () => {
  const dispatch = useCustomerDispatch();
  const {stores, allStores, selectedStore} = useCustomerState();
  const [storeDetails, setStoreDetails] = React.useState(null);

  const parsedStore = useMemo(() => {
    const store = stores.find(({id}) => id === selectedStore);
    return parseStore(store);
  }, [selectedStore, stores]);

  const parsedBranch = useMemo(() => {
    const store = allStores.find(({id}) => id === selectedStore);
    return parseStore(store);
  }, [allStores, selectedStore]);

  useEffect(() => {
    if (parsedStore) {
      return setStoreDetails(parsedStore);
    }

    if (parsedBranch) {
      setStoreDetails(parsedBranch);
    }
  }, [parsedBranch, parsedStore, setStoreDetails]);

  return (
    <SectorCard cardClasses="expandable" onClick={() => toggleStore({dispatch})}>
      {!storeDetails && <SectorTitle title="Κατάστημα" />}
      {storeDetails && (
        <>
          <div>
            Κατάστημα {storeDetails?.brand.charAt(0) + storeDetails?.brand.toLowerCase().slice(1)}
          </div>
          <div className="font-weight-semibold text-primary-main d-flex align-items-center justify-content-between">
            <span>
              <span className={`mx-2 font-weight-semibold ${storeDetails?.brandClass}`}>
                {storeDetails.label}
              </span>
              {storeDetails.title}
            </span>
            <i className="fa fa-angle-down fa-lg" />
          </div>
        </>
      )}
    </SectorCard>
  );
};

export default Store;
