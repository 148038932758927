import {
  TOGGLE_DATETIME,
  SELECT_DATETIME,
  COMPLETE_APPOINTMENT,
  STORE_ORDER
} from 'context/customer/constants/ActionTypes';

const storeOrderDetails = ({dispatch}, payload) => dispatch({type: STORE_ORDER, payload});

const toggleDatetime = ({dispatch}) => dispatch({type: TOGGLE_DATETIME});
const selectDateTime = ({dispatch}, payload) => dispatch({type: SELECT_DATETIME, payload});

const completeAppointment = ({dispatch}) => dispatch({type: COMPLETE_APPOINTMENT});

export {storeOrderDetails, toggleDatetime, selectDateTime, completeAppointment};
