import React from 'react';

const SectorTitle = ({title}) => {
  return (
    <div className="font-weight-semibold text-primary-main d-flex align-items-center justify-content-between">
      {title}
      <i className="fa fa-angle-down fa-lg" />
    </div>
  );
};

export default SectorTitle;
