const CUSTOMER_INITIALIZE = 'CUSTOMER_INITIALIZE';

const STORE_ORDER = 'STORE_ORDER';

const TOGGLE_DATETIME = 'TOGGLE_DATETIME';
const SELECT_DATETIME = 'SELECT_DATE';

const TOGGLE_TERMS = 'TOGGLE_TERMS';

const COMPLETE_APPOINTMENT = 'COMPLETE_APPOINTMENT';

export {
  CUSTOMER_INITIALIZE,
  STORE_ORDER,
  TOGGLE_DATETIME,
  SELECT_DATETIME,
  TOGGLE_TERMS,
  COMPLETE_APPOINTMENT
};
