import createReducer from 'utils/createReducer';
import {
  CUSTOMER_INITIALIZE,
  STORE_ORDER,
  TOGGLE_DATETIME,
  SELECT_DATETIME,
  COMPLETE_APPOINTMENT
} from './constants/ActionTypes';

const initialState = {
  order: {
    storeId: null
  },
  appointmentCompleted: false,
  editable: false,
  datetime: {
    editable: false,
    dateValue: null,
    timeValue: null
  }
};

const reducer = createReducer(initialState, {
  [CUSTOMER_INITIALIZE]: () => initialState,
  [STORE_ORDER]: (state, payload) => ({
    ...state,
    order: payload
  }),
  [TOGGLE_DATETIME]: (state) => ({
    ...state,
    datetime: {
      ...state.datetime,
      editable: !state.datetime.editable
    }
  }),
  [SELECT_DATETIME]: (state, payload) => ({
    ...state,
    datetime: {
      ...state.datetime,
      editable: false,
      ...payload
    }
  }),
  [COMPLETE_APPOINTMENT]: (state) => ({
    ...state,
    appointmentCompleted: true
  })
});

export {reducer, initialState};
