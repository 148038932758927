const loader = (k) => ({
  v: '5.5.7',
  meta: {
    g: 'LottieFiles AE 0.1.20',
    a: '',
    k: '',
    d: '',
    tc: ''
  },
  fr: 60,
  ip: 0,
  op: 120,
  w: 48,
  h: 48,
  nm: 'cosmote-loader',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Shape Layer 2',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 0,
          k: [68.023, 36.629, 0],
          ix: 2
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: {
                a: 0,
                k: [40, 40],
                ix: 2
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3
              },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: k,
                ix: 3
              },
              o: {
                a: 0,
                k: 100,
                ix: 4
              },
              w: {
                a: 0,
                k: 1,
                ix: 5
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.833],
                      y: [0.833]
                    },
                    o: {
                      x: [0.167],
                      y: [0.167]
                    },
                    t: 60,
                    s: [0]
                  },
                  {
                    t: 120,
                    s: [100]
                  }
                ],
                ix: 1
              },
              e: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.833],
                      y: [0.833]
                    },
                    o: {
                      x: [0.167],
                      y: [0.167]
                    },
                    t: 0,
                    s: [0]
                  },
                  {
                    t: 75,
                    s: [100]
                  }
                ],
                ix: 2
              },
              o: {
                a: 0,
                k: 0,
                ix: 3
              },
              m: 1,
              ix: 3,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [-44.023, -12.629],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.833],
                      y: [0.833]
                    },
                    o: {
                      x: [0.167],
                      y: [0.167]
                    },
                    t: 0,
                    s: [0]
                  },
                  {
                    t: 120,
                    s: [360]
                  }
                ],
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Ellipse 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 120,
      st: 0,
      bm: 0
    }
  ],
  markers: []
});

export default loader;
