import React, {useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';

function FilterStores({onFocus, onSetQueryFilter, showList}) {
  const [searchText, setSearchText] = useState('');
  const onChange = (e) => setSearchText(e.target.value);

  useEffect(() => {
    if (!showList) {
      setSearchText('');
    }
  }, [showList]);

  useEffect(() => {
    onSetQueryFilter && onSetQueryFilter(searchText);
  }, [onSetQueryFilter, searchText]);

  return (
    <Form.Control
      id="filter-stores"
      type="text"
      value={searchText}
      onChange={onChange}
      onFocus={onFocus}
      className="rbt-input-main rbt-input"
      placeholder={'Αναζήτηση'}
    />
  );
}

export default FilterStores;
