import React, {Fragment} from 'react';

const ActionInfo = ({cardClasses, textClasses, text, subText, icon, children, hideHr}) => {
  return (
    <Fragment>
      <div className={`text-center action-info ${cardClasses}`}>
        <div className="mb-4">{icon}</div>
        <div className={`font-weight-semibold ${textClasses}`}>{text}</div>
        {subText}
      </div>
      {!hideHr && <hr />}
      {children}
    </Fragment>
  );
};

ActionInfo.defaultProps = {
  cardClasses: '',
  textClasses: 'fs-24',
  subText: '',
  text: ''
};

export default ActionInfo;
