import React from 'react';
import {Col} from 'react-bootstrap';

import {isPublic} from 'utils/brand';
import Super from 'components/common/Super';

const Banner = () => {
  const img = (() => {
    if (!isPublic) {
      return require('assets/images/mediamarkt/img-mm-bg.svg');
    }

    return require('assets/images/public/img-public-bg.svg');
  })();

  return (
    <div
      className="banner"
      style={{
        backgroundImage: `url(${img})`
      }}
    >
      <Col xs={12} lg={6}>
        <Super />
      </Col>
    </div>
  );
};

export default Banner;
