import React, {createContext, useContext, useReducer, useMemo} from 'react';

import {reducer, initialState} from './appointmentReducer';

const AppointmentContext = createContext();
const AppointmentDispatchContext = createContext();

const AppointmentProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const memoizedState = useMemo(() => state, [state]);
  const memoizedDispatch = useMemo(() => dispatch, [dispatch]);

  return (
    <AppointmentContext.Provider value={memoizedState}>
      <AppointmentDispatchContext.Provider value={memoizedDispatch}>
        {children}
      </AppointmentDispatchContext.Provider>
    </AppointmentContext.Provider>
  );
};

function useAppointmentState() {
  const context = useContext(AppointmentContext);
  if (context === undefined) {
    throw new Error('useAppointmentState must be used within a AppointmentProvider');
  }

  return context;
}

function useAppointmentDispatch() {
  const context = useContext(AppointmentDispatchContext);
  if (context === undefined) {
    throw new Error('useAppointmentDispatch must be used within a AppointmentProvider');
  }

  return context;
}

export {AppointmentProvider, useAppointmentState, useAppointmentDispatch};
