import {rest} from 'msw';
import {
  search,
  getBookedAppointments,
  getAppointment,
  getComments,
  count
} from 'mocks/api/v1/appointment';

export const routes = [
  rest.post(`/agent/api/v1/appointment/search`, search),
  rest.get('/agent/api/v1/appointment/booked', getBookedAppointments),
  rest.get('/agent/api/v1/appointment/:appointmentId/comment', getComments),
  rest.get('/agent/api/v1/appointment/:appointmentId', getAppointment),
  rest.get('/agent/api/v1/appointment/store/1/count', count)
];
