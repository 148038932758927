import React from 'react';
import Card from 'react-bootstrap/Card';

const SectorCard = ({cardClasses, cardBodyClasses, onClick, children}) => {
  return (
    <Card className={`sector-card ${cardClasses}`} onClick={onClick}>
      <Card.Body className={cardBodyClasses}>{children}</Card.Body>
    </Card>
  );
};

SectorCard.defaultProps = {
  cardClasses: '',
  cardBodyClasses: '',
  onClick: undefined
};

export default SectorCard;
