import PublicFetch from 'utils/fetch';
import {endpoints} from 'appConfig';
import {brandParam} from 'utils/brand';

const publicFetch = new PublicFetch(endpoints.customer);

const requestAppointment = async ({storeId, date, postData}) => {
  try {
    const {data, headers} = await publicFetch.post(
      `in-shop/store/${storeId}/date/${date}?brand=${brandParam}&lang=el`,
      postData
    );

    return {data, headers};
  } catch (error) {
    throw error;
  }
};

const updateAppointment = async ({storeId, date, lang = 'el', token, postData, brand}) => {
  try {
    const {data} = await publicFetch.post(
      `in-shop/store/${storeId}/date/${date}/update?brand=${brand}&lang=${lang}&token=${token}`,
      postData
    );
    return data;
  } catch (error) {
    throw error;
  }
};

const generateOTP = async ({appointmentId, lang}) => {
  try {
    const {data} = await publicFetch.get(
      `in-shop/otp/generate?appointmentId=${appointmentId}&lang=${lang}&brand=${brandParam}`
    );

    return data;
  } catch (error) {
    throw error;
  }
};

const confirmAppointmentWithOtp = async ({postData, lang, brand = brandParam}) => {
  try {
    const {data, headers} = await publicFetch.post(
      `in-shop/otp/confirm?lang=${lang}&brand=${brand}`,
      postData
    );

    return {data, headers};
  } catch (error) {
    throw error;
  }
};

const fetchAppointmentDetailsOtp = async ({postData, lang}) => {
  try {
    const {data, headers} = await publicFetch.post(`in-shop/otp/details?lang=${lang}`, postData);

    return {data, headers};
  } catch (error) {
    throw error;
  }
};

const fetchAppointmentId = async ({token, lang, brand}) => {
  try {
    const {data} = await publicFetch.get(`in-shop?editToken=${token}&brand=${brand}&lang=${lang}`);

    return data;
  } catch (error) {
    throw error;
  }
};

const cancelAppointment = async ({appointmentId, token, lang = 'el', brand}) => {
  try {
    await publicFetch.delete(`in-shop?brand=${brand}&lang=${lang}&token=${token}`, {
      data: {appointmentId}
    });
  } catch (error) {
    throw error;
  }
};

const getAppointmentDetails = async ({editToken, lang = 'el'}) => {
  try {
    const {data} = await publicFetch.get(`appointment?editToken=${editToken}&lang=${lang}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export {
  requestAppointment,
  generateOTP,
  confirmAppointmentWithOtp,
  fetchAppointmentDetailsOtp,
  updateAppointment,
  fetchAppointmentId,
  cancelAppointment,
  getAppointmentDetails
};
