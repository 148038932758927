import React from 'react';
import Form from 'react-bootstrap/Form';
import {Col} from 'react-bootstrap';
import FieldError from 'components/common/FieldError';

import Button from 'components/common/Button';
import SectorCard from 'components/Customer/SectorCard';
import useForm from 'hooks/useForm';
import customerInfoSchema from 'validations/customerInfoSchema';
import {useCustomerDispatch, useCustomerState} from 'context/customer/customer-context';
import {setCustomerInfo, toggleCustomerInfo} from 'context/customer/clickInShop/actions';
import {isPublic, termsUrl} from 'utils/brand';

const placeholders = {
  name: 'Όνομα',
  surname: 'Επίθετο',
  telephone: 'Τηλέφωνο',
  email: 'Email'
};

const privacyPolicy = () => (
  <span className="font-weight-normal">
    Διάβασα και αποδέχομαι την&nbsp;
    <a href={termsUrl} target="_blank" rel="noopener noreferrer" className="text-primary-main">
      {isPublic ? 'Πολιτική Απορρήτου' : 'Πολιτική Προστασίας'}
    </a>
    &nbsp;{!isPublic && 'προσωπικών δεδομένων '}της Εταιρείας
  </span>
);

const CustomerInfoExpanded = () => {
  const dispatch = useCustomerDispatch();

  const {
    customerInfo: {forename, surname, tel, email, terms}
  } = useCustomerState();
  const {values, handleChange, handleSubmit, handleBlur, valid, touched, errors} = useForm({
    initialValues: {
      forename,
      surname,
      tel,
      email,
      terms
    },
    onSubmit: () => setCustomerInfo({dispatch}, values),
    validationSchema: customerInfoSchema
  });

  const onClose = () => toggleCustomerInfo({dispatch});

  return (
    <SectorCard cardClasses="expanded position-absolute shadow w-100 my-3">
      <div className="font-weight-semibold text-primary-main d-flex align-items-center justify-content-between">
        Στοιχεία πελάτη
        <i className="action-cirlce fa fa-times fa-lg cursor-pointer" onClick={onClose} />
      </div>
      <Form onSubmit={handleSubmit}>
        <Form.Row className="my-4">
          <Col xs={6}>
            <Form.Group controlId="customerNane">
              <Form.Control
                name="forename"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.forename || ''}
                type="text"
                placeholder={placeholders.name}
                className="rbt-input-main rbt-input"
                isInvalid={!!errors.forename && touched}
              />
              <Form.Label>
                <span>{placeholders.name}</span>
              </Form.Label>
              <FieldError error={errors.forename} touched={touched} />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group controlId="customerSurnane">
              <Form.Control
                name="surname"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.surname || ''}
                type="text"
                placeholder={placeholders.surname}
                className="rbt-input-main rbt-input"
                isInvalid={!!errors.surname && touched}
              />
              <Form.Label>
                <span>{placeholders.surname}</span>
              </Form.Label>
              <FieldError error={errors.surname} touched={touched} />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row className="my-4">
          <Col xs={6}>
            <Form.Group controlId="customerTelephone">
              <Form.Control
                name="tel"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.tel || ''}
                type="text"
                placeholder={placeholders.telephone}
                className="rbt-input-main rbt-input"
                isInvalid={!!errors.tel && touched}
              />
              <Form.Label>
                <span>{placeholders.telephone}</span>
              </Form.Label>
              <FieldError error={errors.tel} touched={touched} />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group controlId="customerEmail">
              <Form.Control
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email || ''}
                type="text"
                placeholder={placeholders.email}
                className="rbt-input-main rbt-input"
                isInvalid={!!errors.email && touched}
              />
              <Form.Label>
                <span>{placeholders.email}</span>
              </Form.Label>
              <FieldError error={errors.email} touched={touched} />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col xs={12} className="mb-1 mb-md-2 mx-2 mx-md-4">
            <Form.Check
              name="terms"
              id="terms"
              className="my-3 px-5 px-md-0"
              label={privacyPolicy()}
              type="checkbox"
              checked={values.terms}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Form.Row>
        {valid && (
          <Button
            variant="secondary"
            wrapperClass="text-right mt-4"
            label="Αποθήκευση"
            onClick={handleSubmit}
          />
        )}
      </Form>
    </SectorCard>
  );
};

export default CustomerInfoExpanded;
