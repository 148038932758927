import React from 'react';
import MainCard from '../../common/MainCard';
import ActionInfo from './ActionInfo';
import Button from 'components/common/Button';
import {ReactComponent as SuccessIcon} from 'assets/images/common/icons/ic-success.svg';

const SuccessInfo = ({onBack, hideHr, text, subText, btnLabel}) => {
  return (
    <MainCard>
      <ActionInfo hideHr={hideHr} icon={<SuccessIcon />} text={text} subText={subText}>
        {onBack && (
          <Button
            variant="secondary"
            wrapperClass="text-center my-3"
            label={btnLabel}
            onClick={onBack}
          />
        )}
      </ActionInfo>
    </MainCard>
  );
};

SuccessInfo.defaultProps = {
  cardClasses: '',
  subText: '',
  text: '',
  btnLabel: ''
};

export default SuccessInfo;
