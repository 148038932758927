import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import loader from './loader';
import {isPublic} from 'utils/brand';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loader(
    isPublic ? [0.945098042488, 0.352941185236, 0.133333340287, 1] : [0.874509803922, 0, 0, 1]
  )
};

const Loader = ({wrapperClasses, width, height, messageClasses}) => {
  return (
    <div className={`text-center ${wrapperClasses}`}>
      <div className="mb-4">
        <Lottie options={defaultOptions} width={width} height={height} />
      </div>
      <div className={messageClasses}>Παρακαλώ, περιμένετε</div>
    </div>
  );
};

Loader.defaultProps = {
  wrapperClasses: '',
  messageClasses: 'font-weight-semibold fs-24',
  width: 48,
  height: 48
};

Loader.propTypes = {
  wrapperClasses: PropTypes.string,
  messageClasses: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
};

export default Loader;
