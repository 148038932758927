import React from 'react';
import classnames from 'classnames';
import SectorCard from 'components/Customer/SectorCard';
import SectorTitle from 'components/Customer/Sector/SectorTitle';
import {toggleCustomerInfo} from 'context/customer/clickInShop/actions';
import {useCustomerDispatch, useCustomerState} from 'context/customer/customer-context';

const CustomerInfo = ({enabled}) => {
  const dispatch = useCustomerDispatch();
  const {
    customerInfo: {forename, surname, email, tel, empty}
  } = useCustomerState();

  const onSectorClick = () => enabled && toggleCustomerInfo({dispatch});

  const cardClasses = classnames({
    expandable: enabled,
    'my-3': true
  });

  const cardBodyClasses = classnames({
    opacity: !enabled
  });

  return (
    <SectorCard cardClasses={cardClasses} cardBodyClasses={cardBodyClasses} onClick={onSectorClick}>
      {empty && <SectorTitle title="Στοιχεία πελάτη" />}

      {!empty && (
        <>
          <div>Στοιχεία πελάτη</div>
          <div className="font-weight-semibold text-primary-main d-flex align-items-start justify-content-between">
            <div>
              <div>
                {forename} {surname}
              </div>
              <div>{tel}</div>
              <div>{email}</div>
            </div>
            <i className="fa fa-angle-down fa-lg" />
          </div>
        </>
      )}
    </SectorCard>
  );
};

export default CustomerInfo;
