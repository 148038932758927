import React from 'react';
import MainCard from '../../common/MainCard';
import ActionInfo from './ActionInfo';
import Button from 'components/common/Button';
import {ReactComponent as AlertIcon} from 'assets/images/common/icons/ic-alert.svg';

const WarnInfo = ({onBack, onCancel}) => {
  return (
    <MainCard>
      <ActionInfo
        icon={<AlertIcon className="svg-icon" />}
        text="Θέλεις σίγουρα να ακυρώσεις το ραντεβού;"
      >
        <div className="d-flex justify-content-center">
          <Button variant="secondary" wrapperClass="mr-3" label="Επιστροφή" onClick={onBack} />
          <Button label="Ακύρωση" onClick={onCancel} />
        </div>
      </ActionInfo>
    </MainCard>
  );
};

WarnInfo.defaultProps = {
  cardClasses: '',
  subText: '',
  text: ''
};

export default WarnInfo;
