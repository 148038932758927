import {PUBLIC, MEDIAMARKT} from 'context/customer/clickInShop/constants/Brands';
import createReducer from 'utils/createReducer';
import {
  INITIALIZE,
  TOGGLE_STORE,
  SELECT_STORE,
  TOGGLE_DATETIME,
  TOGGLE_CUSTOMER_INFO,
  SET_BRANDID,
  SET_STORE_QUERY,
  SET_PLACE_DETAILS,
  SET_STORES,
  SET_ALL_STORES,
  SET_SELECTED_STORE,
  SET_DATETIME,
  TOGGLE_CUSTOMERINFO,
  SET_CUSTOMERINFO,
  SET_APPOINTMENT_ID,
  POPULATE_APPOINTMENT,
  SET_DISABLED,
  APPOINTMENT_BOOKED
} from './constants/ActionTypes';
import {isPublic} from 'utils/brand';
import {parseAppointmentViewModel} from 'utils/pojos';

const initialState = {
  editToken: '',
  appointmentId: null,
  selectedStore: null,
  stores: [],
  allStores: [],
  placeDetails: {
    latitude: null,
    longitude: null
  },
  brandId: isPublic ? PUBLIC : MEDIAMARKT,
  storeQuery: '',
  order: {
    storeId: null
  },
  store: {
    editable: false,
    storeValue: null
  },
  datetime: {
    editable: false,
    dateValue: null,
    timeValue: null
  },
  customerInfo: {
    editable: false,
    forename: '',
    surname: '',
    email: '',
    terms: false,
    empty: true
  },
  disabled: false
};

const reducer = createReducer(initialState, {
  [INITIALIZE]: () => initialState,
  [TOGGLE_STORE]: (state) => ({
    ...state,
    store: {
      ...state.store,
      editable: !state.store.editable
    }
  }),
  [SELECT_STORE]: (state, payload) => ({
    ...state,
    store: {
      ...state.store,
      editable: false,
      ...payload
    }
  }),
  [TOGGLE_DATETIME]: (state) => ({
    ...state,
    datetime: {
      ...state.datetime,
      editable: !state.datetime.editable
    }
  }),
  [TOGGLE_CUSTOMER_INFO]: (state) => ({
    ...state,
    customerInfo: {
      ...state.store,
      editable: !state.customerInfo.editable
    }
  }),
  [SET_BRANDID]: (state, payload) => ({
    ...state,
    brandId: payload
  }),
  [SET_STORE_QUERY]: (state, payload) => ({
    ...state,
    storeQuery: payload
  }),
  [SET_PLACE_DETAILS]: (state, payload) => ({
    ...state,
    placeDetails: payload
  }),
  [SET_STORES]: (state, payload) => ({
    ...state,
    stores: payload
  }),
  [SET_ALL_STORES]: (state, payload) => ({
    ...state,
    allStores: payload
  }),
  [SET_SELECTED_STORE]: (state, payload) => ({
    ...state,
    selectedStore: payload
  }),
  [SET_DATETIME]: (state, {date, slot}) => ({
    ...state,
    datetime: {
      ...state.datetime,
      dateValue: date,
      timeValue: slot,
      editable: false
    }
  }),
  [TOGGLE_CUSTOMERINFO]: (state) => ({
    ...state,
    customerInfo: {
      ...state.customerInfo,
      editable: !state.customerInfo.editable
    }
  }),
  [SET_CUSTOMERINFO]: (state, payload) => ({
    ...state,
    customerInfo: {
      ...state.customerInfo,
      ...payload,
      editable: false,
      empty: false
    }
  }),
  [SET_APPOINTMENT_ID]: (state, payload) => ({
    ...state,
    appointmentId: payload.appointmentId
  }),
  [APPOINTMENT_BOOKED]: (state, payload) => ({
    ...state,
    appointmentId: payload.appointmentId,
    editToken: payload.editToken
  }),
  [POPULATE_APPOINTMENT]: (state, payload) => {
    return {
      ...state,
      editToken: payload.editToken,
      allStores: payload.allStores,
      stores: payload.stores,
      ...parseAppointmentViewModel(payload.data)
    };
  },
  [SET_DISABLED]: (state) => ({
    ...state,
    disabled: true
  })
});

export {reducer, initialState};
