import React from 'react';
import Card from 'react-bootstrap/Card';
import classnames from 'classnames';

const MainCard = ({editable, bodyClasses, children, disabled}) => {
  const cardClassNames = classnames('main-card', {
    'pointer-events-none': disabled
  });

  return (
    <Card className={cardClassNames}>
      <Card.Body className={bodyClasses}>
        {editable && <div className="overlay position-absolute w-100 h-100" />}
        <div className="w-100 position-relative">{children}</div>
      </Card.Body>
    </Card>
  );
};

MainCard.defaultProps = {
  editable: false
};

export default MainCard;
