/**
 * Pojo for the branch response object
 * @param {*} branch
 */
const parseStore = (branch) => {
  if (!branch) return null;

  const {id, brand, name, distance, address, city, landline, zipCode, location, ...rest} = branch;

  const brandLowerCase = brand.toLowerCase();

  return {
    label: `${brand} ${location ? ` - ${location}` : ''}${name ? ` ${name}` : ''}${
      distance ? `(${distance} km)` : ''
    }`,
    title: `${address}, ${city} ${zipCode}${landline ? `, ${landline}` : ''}`,
    brandClass: `text-${brandLowerCase}-primary`,
    code: id,
    brand,
    ...rest
  };
};

const parseAppointmentRequestBody = ({appointmentId, appointment, slot}) => {
  try {
    return {
      annotation: appointment.annotation,
      appointmentSlotDto: {
        endTime: slot.endTime,
        time: slot.time
      },
      email: appointment.email,
      forename: appointment.forename,
      hashedOrderId: appointment.hashedOrderId,
      id: appointmentId,
      orderId: appointment.orderId,
      orderStatus: appointment.orderStatus
        ? {
            status: appointment.orderStatus.status
          }
        : null,
      referencePhoneNumber: appointment.referencePhoneNumber,
      requestedTime: slot.time,
      surname: appointment.surname,
      tel: appointment.tel
    };
  } catch (error) {
    throw error;
  }
};

/**
 *
 * @param {AppointmentDTO} appointment
 */
const parseAppointmentViewModel = (appointment) => {
  try {
    return {
      appointmentId: appointment.appointmentId,
      selectedStore: appointment.storeId,
      store: {
        storeName: appointment.storeName
      },
      datetime: {
        dateValue: appointment.date,
        timeValue: {
          endTime: appointment.slotDto.endTime,
          time: appointment.slotDto.time
        }
      },
      customerInfo: {
        forename: appointment.forename,
        surname: appointment.surname,
        email: appointment.email,
        tel: appointment.tel,
        terms: true,
        empty: false
      }
    };
  } catch (error) {
    throw new Error('Could not parse appointment DTO');
  }
};

export {parseStore, parseAppointmentRequestBody, parseAppointmentViewModel};
