import React from 'react';
const {isPublic, domain, brandParam, isClickInShop} = require('utils/brand');

const getLogos = () => {
  const dir = isPublic ? 'public' : 'mediamarkt';
  const logoFile = isPublic ? 'img-public-logo.png' : 'img-mm-logo.svg';
  const pickupFile = isPublic ? 'ic-public-key-value.svg' : 'ic-mm-key-value.svg';
  const clickInShopFile = isPublic ? 'click_in_shop.png' : 'walkin_mediamarkt.png';

  return {
    brandLogo: require(`../../assets/images/${dir}/logo/${logoFile}`),
    pickupLogo: require(`../../assets/images/${dir}/${pickupFile}`),
    clickInShopLogo: require(`../../assets/images/${dir}/${clickInShopFile}`)
  };
};

const Super = () => {
  const {brandLogo, pickupLogo, clickInShopLogo} = getLogos();

  return (
    <div className="super h-100 justify-content-center d-flex flex-column ">
      <div className="d-md-flex text-center">
        <a href={domain} className="marketing-logos cursor-pointer">
          <img src={brandLogo} alt="brand logo" />
        </a>
        {isClickInShop ? (
          <img src={clickInShopLogo} width="150" alt="click in shop logo" />
        ) : (
          <img src={pickupLogo} className="marketing-logos" alt="pickup logo" />
        )}
      </div>
      <div style={{marginTop: '5rem'}}>
        {`Τώρα στα ${brandParam} έχεις την δυνατότητα να προγραμματίσεις πότε θα επισκεφθείς
          ${
            isClickInShop
              ? `το κατάστημα της επιλογής σου για τις αγορές σου.${
                  isPublic
                    ? ` Και μην ξεχάσεις για την μετακίνησή σου προς το κατάστημα να στείλεις SMS στο 13032.`
                    : ''
                }`
              : 'την ασφαλή ζώνη του επιλεγμένου καταστήματος για την παραλαβή της παραγγελίας σου, εύκολα και γρήγορα.'
          }`}
      </div>
    </div>
  );
};

export default Super;
