const APPOINTMENT_INITIALIZE = 'APPOINTMENT_INITIALIZE';
// Search
const SEARCH_APPOINTMENTS = 'SEARCH_APPOINTMENTS';
const SEARCH_APPOINTMENTS_SUCCESS = 'SEARCH_APPOINTMENTS_SUCCESS';
const SEARCH_APPOINTMENTS_FAIL = 'SEARCH_APPOINTMENTS_FAIL';
const TOGGLE_QUERIED = 'TOGGLE_QUERIED';
const FETCH_BOOKED_APPOINTMENTS = 'FETCH_BOOKED_APPOINTMENTS';
const FETCH_BOOKED_APPOINTMENTS_SUCCESS = 'FETCH_BOOKED_APPOINTMENTS_SUCCESS';
const FETCH_BOOKED_APPOINTMENTS_FAIL = 'FETCH_BOOKED_APPOINTMENTS_FAIL';
const SET_APPOINTMENT_STATUSES = 'SET_APPOINTMENT_STATUSES';
const TOGGLE_IN_STORE = 'TOGGLE_IN_STORE';

// Appointment - Customer
const GET_APPOINTMENT_SUCCESS = 'GET_APPOINTMENT_SUCCESS';

// Comments
const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';

export {
  APPOINTMENT_INITIALIZE,
  SEARCH_APPOINTMENTS,
  SEARCH_APPOINTMENTS_SUCCESS,
  SEARCH_APPOINTMENTS_FAIL,
  TOGGLE_QUERIED,
  FETCH_BOOKED_APPOINTMENTS,
  FETCH_BOOKED_APPOINTMENTS_SUCCESS,
  FETCH_BOOKED_APPOINTMENTS_FAIL,
  GET_APPOINTMENT_SUCCESS,
  GET_COMMENTS_SUCCESS,
  SET_APPOINTMENT_STATUSES,
  TOGGLE_IN_STORE
};
