import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useCustomerDispatch} from 'context/customer/customer-context';
import {storeOrderDetails, selectDateTime, completeAppointment} from 'context/customer/actions';
import {publicFetch, fetchOrderDetails, requestAppointment} from 'api/customer';
import {rescheduleAppointment} from 'api/agent/appointment';
import {parseRequestBody} from 'utils/appointment';
import {brandParam} from 'utils/brand';
import {errorInit} from 'utils/error';

const useCustomer = (order, dateValue, timeValue) => {
  const urlParams = new URLSearchParams(decodeURIComponent(useLocation().search));
  const orderId = urlParams.get('orderId');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(errorInit);
  const [terms, setTerms] = useState(false);
  const [appointmentSuccess, setAppointmentSuccess] = useState(false);
  const dispatch = useCustomerDispatch();

  useEffect(() => {
    async function getOrderDetails() {
      setLoading(true);
      try {
        const response = await fetchOrderDetails({hashedOrderId: orderId});
        storeOrderDetails({dispatch}, response);
        if (response.appointmentId) {
          selectDateTime({dispatch}, {dateValue: response.date, timeValue: response.slotDto});
        }
      } catch (error) {
        setError({show: true, code: error.code});
      } finally {
        setLoading(false);
      }
    }

    if (orderId) {
      getOrderDetails();
    }
  }, [orderId, dispatch]);

  const onChangeTerms = () => {
    setTerms((terms) => !terms);
  };

  const onSubmit = async () => {
    const {email, forename, surname, tel, orderId, storeId, hashedOrderId} = order;
    const postData = {
      email,
      forename,
      surname,
      tel,
      orderId,
      requestedTime: timeValue.time,
      appointmentSlotDto: timeValue,
      hashedOrderId
    };

    try {
      setLoading(true);
      await requestAppointment({storeId, date: dateValue, postData});
      completeAppointment({dispatch});
      setAppointmentSuccess(true);
    } catch (error) {
      setError({show: true, code: error.code});
    } finally {
      setLoading(false);
    }
  };

  const onUpdate = async () => {
    try {
      setLoading(true);
      await rescheduleAppointment({
        typeFetch: publicFetch,
        brand: brandParam,
        storeId: order.storeId,
        date: dateValue,
        payload: parseRequestBody({
          appointmentId: order.appointmentId,
          appointment: order,
          slot: timeValue
        })
      });
      completeAppointment({dispatch});
      setAppointmentSuccess(true);
    } catch (error) {
      setError({show: true, code: error.code});
    } finally {
      setLoading(false);
    }
  };

  return [
    loading,
    error,
    terms,
    appointmentSuccess,
    onChangeTerms,
    setError,
    onSubmit,
    onUpdate,
    setAppointmentSuccess
  ];
};

export default useCustomer;
