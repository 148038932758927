import React from 'react';
import Form from 'react-bootstrap/Form';

const FieldError = ({error, touched}) => {
  if (!touched) return null;
  return (
    <Form.Control.Feedback type="invalid" className="font-weight-normal">
      {error}
    </Form.Control.Feedback>
  );
};

export default FieldError;
