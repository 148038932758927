const INITIALIZE = 'INITIALIZE';
const TOGGLE_STORE = 'TOGGLE_STORE';
const SELECT_STORE = 'SELECT_STORE';
const TOGGLE_DATETIME = 'TOGGLE_DATETIME';
const TOGGLE_CUSTOMER_INFO = 'TOGGLE_CUSTOMER_INFO';
const SET_BRANDID = 'SET_BRANDID';
const SET_STORE_QUERY = 'SET_STORE_QUERY';
const SET_PLACE_DETAILS = 'SET_PLACE_DETAILS';
const SET_STORES = 'SET_STORES';
const SET_ALL_STORES = 'SET_ALL_STORES';
const SET_SELECTED_STORE = 'SET_SELECTED_STORE';
const SET_DATETIME = 'SET_DATETIME';
const TOGGLE_CUSTOMERINFO = 'TOGGLE_CUSTOMERINFO';
const SET_CUSTOMERINFO = 'SET_CUSTOMERINFO';
const SET_APPOINTMENT_ID = 'SET_APPOINTMENT_ID';
const POPULATE_APPOINTMENT = 'POPULATE_APPOINTMENT';
const SET_DISABLED = 'SET_DISABLED';
const APPOINTMENT_BOOKED = 'APPOINTMENT_BOOKED';

export {
  INITIALIZE,
  TOGGLE_STORE,
  SELECT_STORE,
  TOGGLE_DATETIME,
  TOGGLE_CUSTOMER_INFO,
  SET_BRANDID,
  SET_STORE_QUERY,
  SET_PLACE_DETAILS,
  SET_STORES,
  SET_ALL_STORES,
  SET_SELECTED_STORE,
  SET_DATETIME,
  TOGGLE_CUSTOMERINFO,
  SET_CUSTOMERINFO,
  SET_APPOINTMENT_ID,
  POPULATE_APPOINTMENT,
  SET_DISABLED,
  APPOINTMENT_BOOKED
};
