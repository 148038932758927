import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import classnames from 'classnames';

const SidebarMenu = ({active, handleMenu, logout}) => {
  const sidebarClasses = classnames('sidebar-menu', {
    active: active
  });

  const menu = [
    {
      icon: 'fa-list-ul',
      href: '',
      label: 'SIDEBAR_MENU.calendar',
      onClick: handleMenu
    },
    {
      icon: 'fa-chart-pie',
      href: '/agent/dashboard',
      label: 'SIDEBAR_MENU.reports',
      onClick: handleMenu
    },
    {
      icon: 'fa-store-alt',
      href: '/agent/login',
      label: 'SIDEBAR_MENU.logout',
      onClick: logout
    }
  ];

  return (
    <div className={sidebarClasses}>
      <div className="px-3 header header--agent d-flex justify-content-between align-items-center">
        <a href="/agent/timeline">
          <img
            src={require(`assets/images/pmm/logo/img-pmm-logo@2x.png`)}
            className="logo logo--agent"
            alt="logo"
          />
        </a>
        <button className="close-button" onClick={handleMenu}>
          <i className="fa fa-times-circle"></i>
        </button>
      </div>
      {menu.map(({icon, href, label, onClick}, index) => (
        <NavLink
          key={index}
          className="my-2 px-3 text-decoration-none d-flex align-items-center text-agent-primary"
          activeClassName="menu-active"
          to={href}
          onClick={onClick}
        >
          <i className={`icon-header fa ${icon} mr-2`} /> {label}
        </NavLink>
      ))}
    </div>
  );
};

SidebarMenu.proptype = {
  active: PropTypes.bool.isRequired,
  handleMenu: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};

export default SidebarMenu;
