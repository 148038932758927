import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Redirect} from 'react-router-dom';

import {useAuth} from 'context/agent/auth-context';
import {isMobile} from 'utils/size';
import {appointmentsRoute} from 'utils/routes';
import LoginForm from './LoginForm';
import useWindowSize from 'hooks/useWindowSize';

const LoginScreen = () => {
  const {isAuthenticated} = useAuth();
  const {width} = useWindowSize();

  return (
    <>
      {isAuthenticated() ? (
        <Redirect to={appointmentsRoute()} />
      ) : (
        <Row
          className="l-login"
          style={{
            backgroundImage: `url(${require('assets/images/public/img-public-bg.svg')})`
          }}
        >
          <Col className="d-flex">
            <Container className="align-items-center justify-content-center flex-grow-1 container d-flex ">
              <Row className="w-100">
                <Col className="d-flex align-items-center">
                  <div className="jumbotron">
                    PICKUP <br /> CORNER
                  </div>
                </Col>
                <Col xl="4" lg="5" className="d-flex justify-content-center align-items-center">
                  <LoginForm isMobile={isMobile(width)} />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      )}
    </>
  );
};

export default LoginScreen;
