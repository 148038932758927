import React from 'react';
import ActionInfo from './ActionInfo';
import Button from 'components/common/Button';
import {ReactComponent as ErrorIcon} from 'assets/images/common/icons/ic-error.svg';
import {errors} from 'utils/error';

const ErrorInfo = ({text, onBack, cardClasses, textClasses, hideHr}) => {
  return (
    <ActionInfo
      cardClasses={cardClasses}
      textClasses={textClasses}
      hideHr={hideHr}
      icon={<ErrorIcon />}
      text={errors[text] ?? 'Κάτι πήγε λάθος'}
      subText="Παρακαλώ, δοκίμασε ξανά"
    >
      {onBack && (
        <Button
          variant="secondary"
          wrapperClass="text-center my-3"
          label="Επιστροφή στην αρχική"
          onClick={onBack}
        />
      )}
    </ActionInfo>
  );
};

export default ErrorInfo;
