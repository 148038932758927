import React from 'react';
import Form from 'react-bootstrap/Form';

import SectorCard from 'components/Customer/SectorCard';

export const searchFieldsTypes = {
  searchByStore: 'searchByStore',
  searchByAddress: 'searchByAddress'
};

const searchFields = [
  {
    id: searchFieldsTypes.searchByStore,
    label: 'Λίστα καταστημάτων'
  },
  {
    id: searchFieldsTypes.searchByAddress,
    label: 'Αναζήτηση με διευθυνση ή T.K'
  }
];

const SearchTypeField = ({onChange, label, icon, id, type = 'radio', checked}) => (
  <Form.Check id={id} inline>
    <Form.Check.Input
      type={type}
      label={label}
      name="searchType"
      value={id}
      onChange={onChange}
      checked={checked}
    />
    <Form.Check.Label>{icon ? <img src={icon} alt={label} /> : label}</Form.Check.Label>
  </Form.Check>
);

const SearchTypes = ({onChangeSearchType}) => {
  const [searchType, setSearchType] = React.useState(searchFieldsTypes.searchByStore);

  const onChange = (e) => setSearchType(e.target.value);

  React.useEffect(() => {
    onChangeSearchType && onChangeSearchType(searchType);
  }, [onChangeSearchType, searchType]);

  return (
    <SectorCard>
      {searchFields.map((field) => (
        <SearchTypeField
          {...field}
          key={field.id}
          checked={searchType === field.id}
          onChange={onChange}
        />
      ))}
    </SectorCard>
  );
};
export default SearchTypes;
