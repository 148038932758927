module.exports = {
  endpoints: {
    agent: '/agent/api/v1',
    customer: '/booking/api/v1'
  },
  googleApi: {
    production: {
      public: 'AIzaSyDEe-UWZhhIVB4DQF0BCc1yR_iB7qqsW9Y',
      mediamarkt: 'AIzaSyCH2r0ou7ey4ygnEF1kasOF6cVRfVCvKqo'
    },
    development: {
      public: 'AIzaSyB6geyixQhNKvj0IT3FOisee6qEzMRRkSI',
      mediamarkt: 'AIzaSyB6geyixQhNKvj0IT3FOisee6qEzMRRkSI'
    }
  }
};
