import React, {useEffect, useState, useRef} from 'react';
import {useCustomerState, useCustomerDispatch} from 'context/customer/customer-context';
import {useRouteMatch, useHistory, useLocation} from 'react-router-dom';

import Loader from 'components/common/Loader';
import ErrorInfo from 'components/Customer/Actions/ErrorInfo';
import Button from 'components/common/Button';
import MainCard from 'components/common/MainCard';
import SuccessInfo from 'components/Customer/Actions/SuccessInfo';
import WarnInfo from 'components/Customer/Actions/WarnInfo';
import Store from './Store';
import StoreExpanded from './Store/StoreExpanded';
import DateTime from './DateTime';
import DateTimeExpanded from 'components/Customer/DateTimeExpanded';
import CustomerInfo from './CustomerInfo';
import CustomerInfoExpanded from './CustomerInfo/CustomerInfoExpanded';

import {
  setDatetime,
  populateAppointment,
  initAppointment,
  setDisabled,
  appointmentBooked
} from 'context/customer/clickInShop/actions';
import appointmentSchema from 'validations/appointmentSchema';
import {parseAppointmentRequestBody} from 'utils/pojos';
import {
  requestAppointment,
  getAppointmentDetails,
  updateAppointment,
  cancelAppointment
} from 'api/customer/in-shop';
import {fetchBranches, fetchAllBranches} from 'api/customer';

const handleError = (error, setError = (error) => error) => {
  const code = error?.code;
  if (![313, 314, 316].includes(code)) {
    return setError(code ?? true);
  }
};

const ClickInShop = () => {
  const urlParams = new URLSearchParams(decodeURIComponent(useLocation().search));
  const history = useHistory();
  const isPopulateRoute = useRouteMatch('/populate')?.url === '/populate';

  const [showVerification, setShowVerification] = useState(false);
  const [canceledSuccess, setCancelSuccess] = useState(false);
  const [isPopulate, setIsPopulate] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [appointmentValid, setAppointmentValidation] = useState(false);
  const hasUpdatedOnce = useRef(false);

  const {
    brandId,
    selectedStore,
    store: {editable: storeIsEditable},
    datetime: {editable: datetimeIsEditable, dateValue, timeValue},
    customerInfo: {editable: customerInfoIsEditable, surname, forename, email, tel, terms},
    appointmentId,
    disabled,
    editToken
  } = useCustomerState();
  const dispatch = useCustomerDispatch();

  useEffect(() => {
    setIsPopulate(isPopulateRoute);
  }, [isPopulateRoute]);

  // Effect happens on populate
  useEffect(() => {
    const onFetchAppointmentDetails = async () => {
      try {
        const token = urlParams.get('token');
        const data = await getAppointmentDetails({
          editToken: token,
          lang: 'el'
        });
        const stores = await fetchBranches({
          brand: brandId,
          coords: {latitude: null, longitude: null},
          lang: 'el'
        });
        const allStores = await fetchAllBranches({brand: brandId});

        populateAppointment({dispatch}, {allStores, stores, data});
      } catch (error) {
        handleError(error, setError);
      }
    };
    if (isPopulate && !appointmentId) {
      onFetchAppointmentDetails();
    }
  }, [appointmentId, brandId, dispatch, isPopulate, urlParams]);

  useEffect(() => {
    const validateAppointment = async () => {
      try {
        await appointmentSchema.validate({
          store: selectedStore,
          datetime: {dateValue, timeValue},
          customerInfo: {surname, forename, email, tel, terms}
        });
        setAppointmentValidation(true);
      } catch (error) {
        setAppointmentValidation(false);
      }
    };
    validateAppointment();
  }, [dateValue, email, forename, selectedStore, surname, tel, terms, timeValue]);

  const onDateSubmit = async (promise) => {
    const {dateTimeState} = await promise;
    setDatetime({dispatch}, dateTimeState);
  };

  const onAppointmentSubmit = async () => {
    try {
      setLoading(true);
      const postData = parseAppointmentRequestBody({
        appointment: {requestedTime: timeValue.time, surname, forename, email, tel},
        slot: {...timeValue}
      });
      const {data, headers} = await requestAppointment({
        storeId: selectedStore,
        date: dateValue,
        postData
      });
      appointmentBooked(
        {dispatch},
        {appointmentId: data.appointmentId, editToken: headers['x-update-token']}
      );
      setShowVerification(true);
      setLoading(false);
    } catch (error) {
      handleError(error, setError);
      setLoading(false);
    }
  };

  const onUpdateAppointment = async () => {
    try {
      setLoading(true);
      const urlToken = urlParams.get('token');
      const postData = parseAppointmentRequestBody({
        appointmentId,
        appointment: {requestedTime: timeValue.time, surname, forename, email, tel},
        slot: {...timeValue}
      });
      await updateAppointment({
        storeId: selectedStore,
        date: dateValue,
        postData,
        brand: brandId,
        token: !isPopulateRoute ? editToken : urlToken
      });
      hasUpdatedOnce.current = true;
      setDisabled({dispatch});
      setLoading(false);
      setShowVerification(true);
    } catch (error) {
      handleError(error, setError);
      setLoading(false);
    }
  };

  const onCancelAppointment = async () => {
    try {
      toggleCancelConfirm();
      setLoading(true);
      const urlToken = urlParams.get('token');

      await cancelAppointment({
        appointmentId,
        brand: brandId,
        token: !isPopulateRoute ? editToken : urlToken
      });
      onInitAppointment();
      setLoading(false);
      setCancelSuccess(true);
    } catch (error) {
      handleError(error, setError);
      setLoading(false);
    }
  };

  const onInitAppointment = () => {
    setIsPopulate(false);
    setCancelSuccess(false);
    initAppointment({dispatch});
    history.push('/');
  };

  const onCloseVerication = () => {
    setIsPopulate(true);
    setShowVerification(false);
  };

  const onChangeStoreId = () =>
    setDatetime({dispatch}, {date: null, slot: {dateValue: null, timeValue: null}});

  const toggleCancelConfirm = () => setCancelConfirm((confirm) => !confirm);

  if (showVerification) {
    const text = isPopulate ? 'ενημερώθηκε' : 'καταχωρήθηκε';
    return (
      <SuccessInfo
        hideHr
        text={`To ραντεβού σου ${text} με επιτυχία!`}
        subText="Θα αποσταλεί ενημερωτικό sms & email με τα στοιχεία του ραντεβού σου. Μπορείς να τροποποιήσεις ή να διαγράψεις το ραντεβού μέσω του sms επιβεβαίωσης."
        onBack={onCloseVerication}
        btnLabel="Δες το ραντεβού"
      />
    );
  }

  if (cancelConfirm) {
    return <WarnInfo onCancel={onCancelAppointment} onBack={toggleCancelConfirm} />;
  }

  if (canceledSuccess) {
    return (
      <SuccessInfo
        hideHr
        text="To ραντεβού σου ακυρώθηκε με επιτυχία!"
        onBack={onInitAppointment}
        btnLabel="Nέο ραντεβού"
      />
    );
  }

  const editable = storeIsEditable || datetimeIsEditable || customerInfoIsEditable;
  const errorHasCTA = ![103].includes(error);

  return (
    <MainCard editable={editable} disabled={disabled}>
      {loading && <Loader wrapperClasses="my-5 py-5 fs-24" />}
      {error && (
        <ErrorInfo
          text={error}
          hideHr={!errorHasCTA}
          onBack={errorHasCTA ? () => setError(false) : false}
        />
      )}
      {!(loading || error) && (
        <>
          <Store />
          <DateTime enabled={Boolean(selectedStore)} />
          <CustomerInfo enabled={dateValue && timeValue} />
          {storeIsEditable && <StoreExpanded onChange={onChangeStoreId} />}
          {datetimeIsEditable && (
            <DateTimeExpanded
              storeId={selectedStore}
              useDispatchHook={useCustomerDispatch}
              useStateHook={useCustomerState}
              onSubmitPromiseCallback={onDateSubmit}
              bookingType="inShop"
            />
          )}
          {customerInfoIsEditable && <CustomerInfoExpanded />}
          <div className="d-flex align-items-center justify-content-center">
            {isPopulate && !hasUpdatedOnce.current && (
              <Button
                variant="secondary"
                wrapperClass="text-center mt-5 mr-3"
                label={'Ακύρωση'}
                onClick={toggleCancelConfirm}
              />
            )}
            {appointmentValid && (
              <>
                {!isPopulate && (
                  <Button
                    variant="primary"
                    wrapperClass="text-center mt-5"
                    label={'Κλείσε ραντεβού'}
                    onClick={onAppointmentSubmit}
                    disabled={!appointmentValid}
                  />
                )}
                {isPopulate && !hasUpdatedOnce.current && (
                  <Button
                    variant="primary"
                    wrapperClass="text-center mt-5"
                    label={'Αλλαγή'}
                    onClick={onUpdateAppointment}
                    disabled={!appointmentValid}
                  />
                )}
              </>
            )}
          </div>
        </>
      )}
    </MainCard>
  );
};

export default ClickInShop;
