import React from 'react';
import classnames from 'classnames';
import moment from 'moment';

import SectorCard from 'components/Customer/SectorCard';
import SectorTitle from 'components/Customer/Sector/SectorTitle';
import {toggleDatetime} from 'context/customer/clickInShop/actions';
import {useCustomerDispatch, useCustomerState} from 'context/customer/customer-context';

const DateTime = ({enabled}) => {
  const dispatch = useCustomerDispatch();
  const {
    datetime: {dateValue, timeValue}
  } = useCustomerState();
  const onSectorClick = () => enabled && toggleDatetime({dispatch});
  const shouldRenderResult = dateValue && timeValue;

  const cardClasses = classnames({
    expandable: enabled,
    'my-3': true
  });
  const cardBodyClasses = classnames({
    opacity: !enabled
  });

  return (
    <SectorCard cardClasses={cardClasses} cardBodyClasses={cardBodyClasses} onClick={onSectorClick}>
      {!shouldRenderResult && <SectorTitle title="Ημερομηνία & ώρα" />}
      {shouldRenderResult && (
        <>
          <div>Ημερομηνία & ώρα</div>
          <div className="font-weight-semibold text-primary-main d-flex align-items-center justify-content-between">
            {`${moment(dateValue).format('dddd, D MMM')}, ${timeValue.time.slice(
              0,
              -3
            )} - ${timeValue.endTime.slice(0, -3)}`}
            <i className="fa fa-angle-down fa-lg" />
          </div>
        </>
      )}
    </SectorCard>
  );
};

export default DateTime;
