import PublicFetch from 'utils/fetch';
import {endpoints} from 'appConfig';
import {brandParam} from 'utils/brand';
import cleanParams from 'utils/cleanParams';

const publicFetch = new PublicFetch(endpoints.customer);

const fetchOrderDetails = async ({hashedOrderId}) => {
  try {
    const {data} = await publicFetch.get(
      `appointment/${hashedOrderId}/orderDetails/hashed?brand=${brandParam}&lang=el`
    );

    return data;
  } catch (error) {
    throw error;
  }
};

const fetchDatesOpen = async ({storeId, bookingType = ''}) => {
  const params = cleanParams({bookingType});
  try {
    const {data} = await publicFetch.request({
      method: 'GET',
      params,
      url: `store/${storeId}/dates-open`
    });
    return data;
  } catch (error) {
    throw error;
  }
};

const fetchAvailableSlots = async ({storeId, date, bookingType = ''}) => {
  const params = cleanParams({bookingType});
  try {
    const {data} = await publicFetch.request({
      method: 'GET',
      params,
      url: `appointment/store/${storeId}/date/${date}/list`
    });

    return data;
  } catch (error) {
    throw error;
  }
};

const fetchBranches = async ({brand, marketplaceBrand, coords, lang}) => {
  const {latitude, longitude} = coords;
  const params = cleanParams({brand, marketplaceBrand, latitude, longitude, lang});

  try {
    const {data} = await publicFetch.request({
      method: 'GET',
      params,
      url: `store/branches`
    });
    return data;
  } catch (error) {
    throw error;
  }
};

const fetchAllBranches = async () => {
  try {
    const {data} = await publicFetch.get(`store/branches/all?brand=${brandParam}`);
    return data;
  } catch (error) {
    throw error;
  }
};

const requestAppointment = async ({storeId, date, postData}) => {
  try {
    const {data} = await publicFetch.post(
      `appointment/store/${storeId}/date/${date}?brand=${brandParam}&lang=el`,
      postData
    );

    return data;
  } catch (error) {
    throw error;
  }
};

const confirmAppointmentWithOtp = async ({postData, lang}) => {
  try {
    const {data} = await publicFetch.post(
      `appointment/otp/confirm?lang=${lang}&brand=${brandParam}`,
      postData
    );

    return data;
  } catch (error) {
    throw error;
  }
};

const fetchAppointmentDetailsOtp = async ({postData, lang}) => {
  try {
    const {data, headers} = await publicFetch.post(
      `appointment/otp/details?lang=${lang}`,
      postData
    );

    return {data, headers};
  } catch (error) {
    throw error;
  }
};

const generateOTP = async ({appointmentId, lang, brand = brandParam}) => {
  try {
    const {data} = await publicFetch.get(
      `appointment/otp/generate?appointmentId=${appointmentId}&lang=${lang}&brand=${brand}`
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export {
  publicFetch,
  fetchOrderDetails,
  fetchDatesOpen,
  fetchAvailableSlots,
  fetchBranches,
  fetchAllBranches,
  requestAppointment,
  confirmAppointmentWithOtp,
  fetchAppointmentDetailsOtp,
  generateOTP
};
