const btnIsDisabled = ({appointmentId, date, slotDto}, dateValue, timeValue, terms) => {
  const valid = [Boolean(dateValue), Boolean(timeValue), terms];

  if (appointmentId) {
    valid.push(
      date !== dateValue || slotDto.time !== timeValue.time || slotDto.endTime !== timeValue.endTime
    );
  }

  return valid.includes(false);
};

export {btnIsDisabled};
