import React from 'react';
import Form from 'react-bootstrap/Form';
import {Redirect} from 'react-router-dom';
import isEmpty from 'lodash.isempty';

import useForm from 'hooks/useForm';
import loginSchema from 'validations/loginSchema';
import {useAuth} from 'context/agent/auth-context';
import {appointmentsRoute} from 'utils/routes';
import {authenticate} from 'api/agent/auth';
import Loader from 'components/common/Loader';
import MainCard from 'components/common/MainCard';
import Button from 'components/common/Button';

const placeholders = {
  username: 'Όνομα χρήστη',
  password: 'Κωδικός'
};

function LoginForm({isMobile}) {
  const [loginError, setLoginError] = React.useState('');
  const [redirectOnLogin, setRedirectOnLogin] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const auth = useAuth();

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    valid,
    touched,
    setErrors,
    errors
  } = useForm({
    initialValues: {
      username: '',
      password: ''
    },
    onSubmit: submitForm,
    validationSchema: loginSchema
  });

  const loaderIconDimension = isMobile ? 40 : 80;

  async function submitForm() {
    const {username, password} = values;
    setIsLoading(true);
    try {
      const data = await authenticate({username, password});
      setIsLoading(false);
      auth.setAuthState(data);
      setErrors({});
      setTimeout(() => {
        setRedirectOnLogin(true);
      }, 1000);
    } catch (error) {
      setIsLoading(false);
      // Uncaught errors from BE we handle them as generic error
      if (isEmpty({...error})) {
        return setLoginError('H υπηρεσία δεν είναι διαθέσιμη, παρακαλώ δοκιμάστε αργότερα');
      }
      if (
        error?.code === 600 ||
        (error.response.status && [401, 403].includes(error.response.status))
      ) {
        return setLoginError('Το username ή password είναι λάθος');
      }
      setLoginError('H υπηρεσία δεν είναι διαθέσιμη, παρακαλώ δοκιμάστε αργότερα');
    }
  }

  return (
    <>
      {redirectOnLogin && <Redirect to={appointmentsRoute()} />}
      <Form onSubmit={handleSubmit} className="w-100">
        <MainCard>
          {isLoading && (
            <Loader
              wrapperClasses="my-5 fs-20 fs-md-26"
              width={loaderIconDimension}
              height={loaderIconDimension}
            />
          )}
          {!isLoading && (
            <>
              <div>
                <h3>Σύνδεση</h3>
                {loginError && <div className="login-form-error fs-16 mt-5">{loginError}</div>}
                <Form.Group controlId="formBasicEmail" className="mt-5">
                  <Form.Control
                    name="username"
                    type="text"
                    placeholder={placeholders.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                    isInvalid={!!errors.username && touched}
                  />
                  <Form.Label className="mb-3">
                    <span>{placeholders.username}</span>
                  </Form.Label>
                  <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formBasicPassword" className="mt-4">
                  <Form.Control
                    name="password"
                    type="password"
                    placeholder={placeholders.password}
                    onChange={handleChange}
                    value={values.password}
                    isInvalid={!!errors.password && touched}
                  />
                  <Form.Label className="mb-3">
                    <span>{placeholders.password}</span>
                  </Form.Label>
                  <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="text-right">
                <Button type="submit" className="button" disabled={!valid} label="Σύνδεση" />
              </div>
            </>
          )}
        </MainCard>
      </Form>
    </>
  );
}

export default LoginForm;
