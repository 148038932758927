import PublicFetch from 'utils/fetch';
import {endpoints} from 'appConfig';

const publicFetch = new PublicFetch(endpoints.agent);

export async function authenticate(body) {
  try {
    const {data} = await publicFetch.post('/auth/signin', body);

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
