import React, {useEffect, useCallback, useState, useRef} from 'react';
import {Col, Row} from 'react-bootstrap';

import Button from 'components/common/Button';
import SearchType, {searchFieldsTypes} from './SearchType';
import AutoComplete from './AutoComplete';
import FilterStores from './FilterStores';
import List from './List';
import SectorCard from 'components/Customer/SectorCard';
import SectorExpTitle from 'components/Customer/Sector/SectorExpTitle';
import Loader from 'components/common/Loader';
import ErrorInfo from 'components/Customer/Actions/ErrorInfo';
import {useCustomerDispatch, useCustomerState} from 'context/customer/customer-context';
import {
  toggleStore,
  selectStore,
  setPlaceDetails,
  setStores,
  setAllStores,
  setSelectedStore
} from 'context/customer/clickInShop/actions';
import {fetchBranches, fetchAllBranches} from 'api/customer';
import {parseStore} from 'utils/pojos';

const StoreExpanded = ({onChange}) => {
  const dispatch = useCustomerDispatch();
  const state = useCustomerState();
  const {
    brandId,
    placeDetails: {latitude, longitude},
    stores,
    allStores,
    selectedStore
  } = state;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [fetched, setFetched] = useState(false);
  const previoustStoreId = useRef(null);
  const [searchType, setSearchType] = useState(searchFieldsTypes.searchByStore);
  const [queryFilter, setQueryFilter] = useState('');

  const handleSearchTypeField = (type) => setSearchType(type);
  const handlePlaceDetails = (coords) => setPlaceDetails({dispatch}, coords);
  const handleListChange = (e) => {
    setSelectedStore({dispatch}, e.target.value);
  };

  const onFocus = () => {
    setError(false);
  };

  const handleStores = useCallback((stores) => setStores({dispatch}, stores), [dispatch]);
  const handleAllStores = useCallback((stores) => setAllStores({dispatch}, stores), [dispatch]);
  const onFetchStores = useCallback(async () => {
    const invalidCoords = [latitude, longitude].some((v) => !v);
    try {
      setLoading(true);
      const stores = await fetchBranches({
        brand: brandId,
        coords: !invalidCoords ? {latitude, longitude} : null,
        lang: 'el'
      });
      handleStores(stores);
      setFetched(true);
    } catch (error) {
      setError(error?.code ?? true);
    } finally {
      setLoading(false);
    }
  }, [brandId, handleStores, latitude, longitude]);

  useEffect(() => {
    if (latitude && longitude) {
      onFetchStores();
    }
  }, [latitude, longitude, onFetchStores]);

  const onFetchAllStores = useCallback(async () => {
    try {
      setLoading(true);
      const allStores = await fetchAllBranches();
      handleAllStores(allStores);
    } catch (error) {
      setError(error?.code ?? true);
    } finally {
      setLoading(false);
    }
  }, [handleAllStores]);

  useEffect(() => {
    onFetchAllStores();
  }, [onFetchAllStores]);

  useEffect(() => {
    if (previoustStoreId.current !== selectedStore) {
      previoustStoreId.current = selectedStore;
      onChange();
    }
  }, [selectedStore, previoustStoreId, onChange]);

  const onSetQueryFilter = React.useCallback((str) => setQueryFilter(str), []);

  const showByStore = searchType === searchFieldsTypes.searchByStore;
  const showByAddress = searchType === searchFieldsTypes.searchByAddress;

  return (
    <SectorCard cardClasses="expanded position-absolute shadow w-100">
      <SectorExpTitle title="Διάλεξε κατάστημα" onClick={() => toggleStore({dispatch})} />
      <SearchType onChangeSearchType={handleSearchTypeField} />
      {brandId && (
        <Row className="my-4">
          {showByStore && (
            <Col className="my-2">
              <FilterStores
                options={allStores}
                onChange={handleListChange}
                selectedStore={selectedStore}
                onSetQueryFilter={onSetQueryFilter}
              />
            </Col>
          )}
          {showByAddress && (
            <Col className="my-2">
              <AutoComplete onSetPlaceDetails={handlePlaceDetails} onFocus={onFocus} />
            </Col>
          )}
          <Col xs="12" className="mt-5">
            {loading && <Loader messageClasses="fs-16" />}
            {error && <ErrorInfo text={error} cardClasses="m-0 fs-16" textClasses="fs-16" hideHr />}
            {!(loading || error) && (
              <List
                query={queryFilter}
                showByAddress={showByAddress}
                list={(showByAddress ? stores : allStores) ?? []}
                onChange={handleListChange}
                selectedStore={selectedStore}
                parser={parseStore}
                fetched={fetched}
              />
            )}
          </Col>
        </Row>
      )}

      {selectedStore && (
        <Button
          variant="secondary"
          wrapperClass="text-right mt-4"
          label="Αποθήκευση"
          onClick={() => selectStore({dispatch}, {storeValue: 'store'})}
        />
      )}
    </SectorCard>
  );
};

export default StoreExpanded;
