import React from 'react';
import moment from 'moment';
import classnames from 'classnames';

import SectorCard from './SectorCard';
import {useCustomerDispatch} from 'context/customer/customer-context';
import {toggleDatetime} from 'context/customer/actions';

const DateTime = ({appointmentCompleted, dateValue, timeValue}) => {
  const dispatch = useCustomerDispatch();
  const formCompleted = dateValue && timeValue;
  const cardClasses = classnames({
    expandable: !appointmentCompleted
  });
  const title = 'Ημερομηνία & ώρα παραλαβής';

  return (
    <SectorCard
      cardClasses={cardClasses}
      onClick={() => (!appointmentCompleted ? toggleDatetime({dispatch}) : undefined)}
    >
      {formCompleted && title}
      <div className="font-weight-semibold text-primary-main d-flex align-items-center justify-content-between">
        {formCompleted
          ? `${moment(dateValue).format('dddd, D MMM')}, ${timeValue.time.slice(
              0,
              -3
            )} - ${timeValue.endTime.slice(0, -3)}`
          : title}
        {!appointmentCompleted && <i className="fa fa-angle-down fa-lg" />}
      </div>
    </SectorCard>
  );
};

export default DateTime;
