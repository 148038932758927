import React, {useRef, useCallback, useLayoutEffect, useState, useEffect} from 'react';
import Form from 'react-bootstrap/Form';

import {apiKey} from 'utils/google';
import injectScript from 'utils/injectScript';

const placeholder = 'Αναζήτησή με διεύθυνση ή ΤΚ';

const AutoComplete = ({onSetPlaceDetails, onFocus, showList}) => {
  const autoCompleteRef = useRef();
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (!showList) {
      setSearchText('');
    }
  }, [showList]);

  const onChange = (e) => setSearchText(e.target.value);

  const getAddress = (autoComplete) => {
    const place = autoComplete.getPlace();
    if (place.geometry) {
      setSearchText(place.formatted_address);
      onSetPlaceDetails({
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng()
      });
    }
  };

  const handleScriptLoad = (setSearchText, autoCompleteRef) => {
    const autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
      types: ['geocode', 'establishment'],
      componentRestrictions: {country: 'gr'}
    });

    window.google.maps.event.addListener(autoComplete, 'place_changed', () => {
      getAddress(autoComplete, setSearchText);
    });
  };

  const scriptLoadCallback = useCallback(handleScriptLoad, []);

  useLayoutEffect(() => {
    if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
      scriptLoadCallback(setSearchText, autoCompleteRef);
    } else {
      injectScript(`https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`, () =>
        scriptLoadCallback(setSearchText, autoCompleteRef)
      );
    }
  }, [scriptLoadCallback]);

  return (
    <Form.Control
      id="google-autocomplete"
      type="text"
      value={searchText}
      onChange={onChange}
      onFocus={onFocus}
      className="rbt-input-main rbt-input"
      placeholder={placeholder}
      ref={autoCompleteRef}
    />
  );
};

export default AutoComplete;
