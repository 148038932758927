import React from 'react';
import Form from 'react-bootstrap/Form';

import {useCustomerState} from 'context/customer/customer-context';
import Button from 'components/common/Button';
import MainCard from '../common/MainCard';
import SectorCard from './SectorCard';
import DateTime from './DateTime';
import DateTimeExpanded from './DateTimeExpanded/index';
import ErrorInfo from './Actions/ErrorInfo';
import SuccessInfo from './Actions/SuccessInfo';
import {termsUrl} from 'utils/brand';
import Loader from 'components/common/Loader';
import useCustomerOrder from 'hooks/useCustomerOrder';
import {errorInit, errors} from 'utils/error';
import {btnIsDisabled} from './helper';

const Customer = () => {
  const {
    order,
    datetime: {editable, dateValue, timeValue},
    appointmentCompleted
  } = useCustomerState();
  const [
    loading,
    error,
    terms,
    appointmentSuccess,
    onChangeTerms,
    setError,
    onSubmit,
    onUpdate,
    setAppointmentSuccess
  ] = useCustomerOrder(order, dateValue, timeValue);

  if (loading) {
    return (
      <MainCard>
        <Loader wrapperClasses="customer-loader" />
      </MainCard>
    );
  }

  if (error.show) {
    return (
      <MainCard>
        <ErrorInfo
          text={errors[error.code]}
          onBack={order ? () => setError(errorInit) : undefined}
        />
      </MainCard>
    );
  }

  if (appointmentSuccess) {
    return <SuccessInfo onBack={() => setAppointmentSuccess(false)} />;
  }

  if (!order.orderId) {
    return (
      <MainCard>
        <ErrorInfo text="Η παραγγελία δεν βρέθηκε" />
      </MainCard>
    );
  }

  const formCompleted = dateValue && timeValue;
  const btnDisabled = btnIsDisabled(order, dateValue, timeValue, terms);

  const {orderId, forename, surname, tel, email, storeName} = order;

  return (
    <MainCard editable={editable}>
      <SectorCard cardClasses="my-3">
        Παραγγελία #{orderId}
        <div className="font-weight-semibold">
          {forename} {surname}
          <br />
          {tel}
          <br />
          {email}
        </div>
      </SectorCard>
      <SectorCard cardClasses="my-3">
        Κατάστημα παραλαβής
        <div className="font-weight-semibold">{storeName}</div>
      </SectorCard>
      <DateTime
        appointmentCompleted={appointmentCompleted}
        dateValue={dateValue}
        timeValue={timeValue}
      />
      {!appointmentCompleted && formCompleted && (
        <div className="my-3 d-flex justify-content-center">
          <Form.Check
            id="checkbox-terms"
            className="px-5 px-md-0 d-flex align-items-center"
            label={
              <span>
                Διάβασα & συναινώ με τους{' '}
                <a
                  href={termsUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary-main"
                >
                  όρους & προϋποθέσεις
                </a>
              </span>
            }
            value="terms"
            checked={terms}
            type="checkbox"
            onChange={onChangeTerms}
          />
        </div>
      )}
      {!appointmentCompleted && (
        <Button
          wrapperClass="text-center my-4"
          label="Κλείσε ραντεβού"
          disabled={btnDisabled}
          onClick={order.appointmentId ? onUpdate : onSubmit}
        />
      )}
      {editable && <DateTimeExpanded />}
    </MainCard>
  );
};

export default Customer;
