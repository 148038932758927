import {
  TOGGLE_STORE,
  SELECT_STORE,
  SET_BRANDID,
  SET_STORE_QUERY,
  SET_PLACE_DETAILS,
  SET_STORES,
  SET_ALL_STORES,
  SET_SELECTED_STORE,
  TOGGLE_DATETIME,
  SET_DATETIME,
  TOGGLE_CUSTOMERINFO,
  SET_CUSTOMERINFO,
  SET_APPOINTMENT_ID,
  POPULATE_APPOINTMENT,
  INITIALIZE,
  SET_DISABLED,
  APPOINTMENT_BOOKED
} from './constants/ActionTypes';

const toggleStore = ({dispatch}) => dispatch({type: TOGGLE_STORE});
const selectStore = ({dispatch}, payload) => dispatch({type: SELECT_STORE, payload});
const setBrandId = ({dispatch}, payload) => dispatch({type: SET_BRANDID, payload});
const setStoreQuery = ({dispatch}, payload) => dispatch({type: SET_STORE_QUERY, payload});
const setPlaceDetails = ({dispatch}, payload) => dispatch({type: SET_PLACE_DETAILS, payload});
const setStores = ({dispatch}, payload) => dispatch({type: SET_STORES, payload});
const setAllStores = ({dispatch}, payload) => dispatch({type: SET_ALL_STORES, payload});
const setSelectedStore = ({dispatch}, payload) => dispatch({type: SET_SELECTED_STORE, payload});
const toggleDatetime = ({dispatch}) => dispatch({type: TOGGLE_DATETIME});
const setDatetime = ({dispatch}, payload) => dispatch({type: SET_DATETIME, payload});
const toggleCustomerInfo = ({dispatch}) => dispatch({type: TOGGLE_CUSTOMERINFO});
const setCustomerInfo = ({dispatch}, payload) => dispatch({type: SET_CUSTOMERINFO, payload});
const setAppointmentId = ({dispatch}, payload) => dispatch({type: SET_APPOINTMENT_ID, payload});
const populateAppointment = ({dispatch}, payload) =>
  dispatch({type: POPULATE_APPOINTMENT, payload});
const setDisabled = ({dispatch}) => dispatch({type: SET_DISABLED});
const initAppointment = ({dispatch}) => dispatch({type: INITIALIZE});
const appointmentBooked = ({dispatch}, payload) => dispatch({type: APPOINTMENT_BOOKED, payload});

export {
  toggleStore,
  selectStore,
  setBrandId,
  setStoreQuery,
  setPlaceDetails,
  setStores,
  setAllStores,
  setSelectedStore,
  toggleDatetime,
  setDatetime,
  toggleCustomerInfo,
  setCustomerInfo,
  setAppointmentId,
  populateAppointment,
  initAppointment,
  setDisabled,
  appointmentBooked
};
