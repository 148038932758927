import React, {useMemo} from 'react';
import FormCheck from 'react-bootstrap/FormCheck';

const StoreList = ({
  list = [],
  query,
  fetched,
  onChange,
  selectedStore,
  parser = () => [],
  showByAddress
}) => {
  const branches = useMemo(() => list.map((branch) => parser(branch)), [list, parser]);
  const filterByQuery = (item) => {
    if (showByAddress) return true;
    // eslint-disable-next-line no-unused-vars
    const {brandClass, code, type, ...rest} = item;
    return Object.keys(rest).some((key) =>
      item[key]
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(query.toLowerCase())
    );
  };

  return (
    <div className="search-stores">
      {fetched && branches.length === 0 && (
        <div className="text-center">Δεν βρέθηκαν καταστήματα</div>
      )}
      {branches.filter(filterByQuery).map(({code, label, title, icon, brandClass}) => (
        <FormCheck
          key={code}
          id={`store-${code}`}
          className="form-element"
          value={code || ''}
          checked={code === selectedStore}
          name="radio-search-store"
          type="radio"
          label={
            <div className="d-flex align-items-center">
              {icon && <img src={icon} className="align-self-start mt-1" width="16" alt="brand" />}
              <span>
                <span className={`mx-2 font-weight-semibold ${brandClass}`}>{label}</span>
                {title}
              </span>
            </div>
          }
          onChange={onChange}
        />
      ))}
    </div>
  );
};

export default StoreList;
