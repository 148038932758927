const parseRequestBody = ({appointmentId, appointment, slot}) => {
  try {
    return {
      annotation: appointment.annotation,
      appointmentSlotDto: {
        endTime: slot.endTime,
        time: slot.time
      },
      email: appointment.email,
      forename: appointment.forename,
      hashedOrderId: appointment.hashedOrderId,
      id: appointmentId,
      orderId: appointment.orderId,
      orderStatus: appointment.orderStatus
        ? {
            status: appointment.orderStatus.status
          }
        : null,
      referencePhoneNumber: appointment.referencePhoneNumber,
      requestedTime: slot.time,
      surname: appointment.surname,
      tel: appointment.tel
    };
  } catch (error) {
    throw error;
  }
};

export {parseRequestBody};
