import React from 'react';

import useCustomerDatetime from 'hooks/useCustomerDatetime';
import {useCustomerState, useCustomerDispatch} from 'context/customer/customer-context';
import DateTimeExpandedView from './View';

const DateTimeExpanded = ({
  storeId,
  useDispatchHook,
  useStateHook,
  onSubmitPromiseCallback,
  bookingType
}) => {
  const dateTimeProps = useCustomerDatetime({
    storeId,
    bookingType,
    useDispatchHook: useDispatchHook || useCustomerDispatch,
    useStateHook: useStateHook || useCustomerState,
    onSubmitPromiseCallback
  });

  return <DateTimeExpandedView {...dateTimeProps} />;
};

export default DateTimeExpanded;
