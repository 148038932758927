import cleanParams from 'utils/cleanParams';

const searchAppointment = async ({authAxios, brand, value, lang, storeId, bookingType}) => {
  const params = cleanParams({brand, value, lang, storeId, bookingType});

  try {
    const {data} = await authAxios.request({
      method: 'GET',
      params,
      url: `/appointment/search`
    });
    return data;
  } catch (error) {
    throw error;
  }
};

const getBookedAppointments = async ({authAxios, lang, storeId, date, brand, bookingType}) => {
  const params = cleanParams({lang, storeId, date, brand, bookingType});

  try {
    const {data} = await authAxios.request({
      method: 'GET',
      params,
      url: `/appointment/booked`
    });

    return data;
  } catch (error) {
    throw error;
  }
};

const getAppointmentComment = async ({authAxios, appointmentId}) => {
  try {
    const {data} = await authAxios.get(`/appointment/${appointmentId}/comment`);
    return data;
  } catch (error) {
    throw error;
  }
};

const getAppointment = async ({authAxios, appointmentId, lang}) => {
  try {
    const {data} = await authAxios.get(`/appointment/${appointmentId}?lang=${lang}`);

    return data;
  } catch (error) {
    throw error;
  }
};

const commentAppointment = async ({authAxios, appointmentId, comment}) => {
  try {
    await authAxios.post(`/appointment/${appointmentId}/comment`, {comment});
  } catch (error) {
    throw error;
  }
};

const getAppointmentCount = async (authAxios, {month, year, storeId, brand, bookingType}) => {
  try {
    const {data} = await authAxios.get(
      `appointment/store/${storeId}/count?month=${month}&year=${year}&brand=${brand}&bookingType=${bookingType}`
    );

    return data;
  } catch (error) {
    throw error;
  }
};

const createNewAppointment = async (authAxios, storeId, date, brand, postData) => {
  try {
    await authAxios.post(
      `appointment/store/${storeId}/date/${date}?brand=${brand}&lang=el`,
      postData
    );
  } catch (error) {
    throw error;
  }
};

const getAppointmentStatuses = async ({authAxios}) => {
  try {
    const {data} = await authAxios.get(`/appointment/orderStatuses`);

    return data;
  } catch (error) {
    throw error;
  }
};

const setAppointmentStatus = async ({authAxios, appointmentId, orderStatusDto}) => {
  try {
    const {data} = await authAxios.put(
      `/appointment/${appointmentId}/order/status`,
      orderStatusDto
    );
    return data;
  } catch (error) {
    throw error;
  }
};

const setAppointmentNoShow = async ({authAxios, appointmentId}) => {
  try {
    const {data} = await authAxios.put(`/appointment/${appointmentId}/noShow`);
    return data;
  } catch (error) {
    throw error;
  }
};

const setAppointmentCompleted = async ({authAxios, appointmentId}) => {
  try {
    const {data} = await authAxios.post(`/appointment/${appointmentId}/stop`, {
      subStatus: 'SUCCESS'
    });
    return data;
  } catch (error) {
    throw error;
  }
};

const rescheduleAppointment = async ({typeFetch, brand, storeId, date, payload}) => {
  try {
    const {data} = await typeFetch.post(
      `/appointment/store/${storeId}/date/${date}/update?brand=${brand}`,
      payload
    );
    return data;
  } catch (error) {
    throw error;
  }
};

const getCSVReports = async ({authAxios, brand, startDate, endDate, bookingType}) => {
  try {
    const {data} = await authAxios.get(
      `/appointment/report/csv?brand=${brand}&startDate=${startDate}&endDate=${endDate}&bookingType=${bookingType}`
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export {
  searchAppointment,
  getBookedAppointments,
  getAppointmentComment,
  getAppointment,
  commentAppointment,
  getAppointmentCount,
  createNewAppointment,
  getAppointmentStatuses,
  rescheduleAppointment,
  setAppointmentStatus,
  getCSVReports,
  setAppointmentNoShow,
  setAppointmentCompleted
};
