import * as yup from 'yup';

export default yup.object().shape({
  forename: yup
    .string()
    .required('Το πεδίο είναι υποχρεωτικό')
    .min(3, 'Μή έγκυρο Όνομα')
    .max(30, 'Μή έγκυρο Όνομα')
    .matches(/^[a-zA-Zά-ωΑ-ώ]+(([',. -][a-zA-Zά-ωΑ-ώ ])?[a-zA-Zά-ωΑ-ώ]*)*$/g, 'Μή έγκυρο Όνομα'),
  surname: yup
    .string()
    .required('Το πεδίο είναι υποχρεωτικό')
    .min(3, 'Μή έγκυρο Eπίθετο')
    .max(30, 'Μή έγκυρο Επίθετο')
    .matches(/^[a-zA-Zά-ωΑ-ώ]+(([',. -][a-zA-Zά-ωΑ-ώ ])?[a-zA-Zά-ωΑ-ώ]*)*$/g, 'Μή έγκυρο Επίθετο'),
  tel: yup
    .string()
    .required('Το πεδίο είναι υποχρεωτικό')
    .matches(/^69[0-9]{8}$/, 'Μή έγκυρος αριθμός κινητου'),
  email: yup.string().email('Μή έγκυρο email').nullable(),
  terms: yup.boolean().oneOf([true], 'Το πεδίο είναι υποχρεωτικό')
});
