import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import Loader from 'components/common/Loader';
import ErrorInfo from 'components/Customer/Actions/ErrorInfo';
import Button from 'components/common/Button';
import SectorCard from 'components/Customer/SectorCard';
import {errors} from 'utils/error';

const DateTimeExpandedView = ({
  loadingDate,
  errorDate,
  loadingSlot,
  errorSlot,
  datesOpen,
  availableSlots,
  date,
  slot,
  onClose,
  onClickDate,
  setSlot,
  onSubmit,
  wrapperClass
}) => {
  return (
    <Card
      className={['sector-card expanded position-absolute shadow w-100', wrapperClass].join(' ')}
    >
      <Card.Body>
        {loadingDate && <Loader wrapperClasses="m-4" messageClasses="fs-16" />}
        {errorDate.show && <ErrorInfo text={errors[errorDate.code]} />}
        {!(loadingDate || errorDate.show) && (
          <>
            <div className="font-weight-semibold text-primary-main d-flex align-items-center justify-content-between">
              Διάλεξε ημερομηνία
              <i className="action-cirlce fa fa-times fa-lg cursor-pointer" onClick={onClose} />
            </div>
            <SectorCard cardClasses="divider my-2" cardBodyClasses="p-4">
              <div className="slot-dates d-flex justify-content-between pb-3">
                {datesOpen.map(({date: dateOpen, available}) => {
                  const selectedDate = date === dateOpen;
                  const classes = classnames('slot date', {
                    disabled: !available,
                    selected: selectedDate
                  });

                  return (
                    <div
                      key={dateOpen}
                      className="d-flex flex-column align-items-center mr-3"
                      onClick={() =>
                        available || !selectedDate ? onClickDate(dateOpen) : undefined
                      }
                    >
                      {moment(dateOpen).format('dd')}
                      <span className={classes}>{moment(dateOpen).format('D')}</span>
                    </div>
                  );
                })}
              </div>
            </SectorCard>
            {date && (
              <>
                {loadingSlot && <Loader wrapperClasses="m-4" messageClasses="fs-16" />}
                {errorSlot.show && <ErrorInfo text={errors[errorDate.code]} />}
                {!(loadingSlot || errorSlot.show) && (
                  <>
                    <div className="mt-5 mb-2 font-weight-semibold text-primary-main">
                      Διάλεξε ώρα
                    </div>
                    <Row className="m-0">
                      {availableSlots.map(({time, endTime, available}) => {
                        const period = `${time.slice(0, -3)} - ${endTime.slice(0, -3)}`;
                        const classes = classnames('slot time', {
                          disabled: !available,
                          selected: time === slot?.time && endTime === slot?.endTime
                        });

                        return (
                          <Col
                            key={period}
                            xs={6}
                            sm={3}
                            className="px-1"
                            onClick={() => (available ? setSlot({time, endTime}) : undefined)}
                          >
                            <div className={classes}>{period}</div>
                          </Col>
                        );
                      })}
                    </Row>
                  </>
                )}
              </>
            )}
            {date && slot && (
              <Button
                variant="secondary"
                wrapperClass="text-right mt-4"
                label="Αποθήκευση"
                onClick={onSubmit}
              />
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
};

DateTimeExpandedView.propTypes = {
  loadingDate: PropTypes.any.isRequired,
  errorDate: PropTypes.any.isRequired,
  loadingSlot: PropTypes.any.isRequired,
  errorSlot: PropTypes.any.isRequired,
  datesOpen: PropTypes.any.isRequired,
  availableSlots: PropTypes.any.isRequired,
  date: PropTypes.any,
  slot: PropTypes.any,
  onClose: PropTypes.any.isRequired,
  onClickDate: PropTypes.any.isRequired,
  setSlot: PropTypes.any.isRequired,
  onSubmit: PropTypes.any.isRequired
};

export default DateTimeExpandedView;
