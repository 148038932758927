import * as yup from 'yup';

import customerInfoSchema from './customerInfoSchema';

const appointmentSchema = yup.object({
  store: yup.string().required(),
  datetime: yup.object().shape({
    dateValue: yup.string().required(),
    timeValue: yup.object().shape({endTime: yup.string().required(), time: yup.string().required()})
  }),
  customerInfo: customerInfoSchema
});

export default appointmentSchema;
