import isEmpty from 'lodash.isempty';

/**
 * Assert when object[key] matches acceptance values
 * @param {Object} obj
 * @param {String} key
 * @param {*} value
 *
 * @returns {Boolean}
 */
function hasValue(obj, key, param) {
  if (Array.isArray(param)) {
    return (
      obj.hasOwnProperty(key) &&
      param.some((item) => {
        return obj[key] === item;
      })
    );
  }

  return obj[key] === param;
}

/**
 * Cleans object attributes with values specified in @param params
 * params could me a primitive value or array
 * @param {Object} payload
 * @param {*} params
 * @returns {Object} payload
 */
export default function cleanParams(payload, params = ['', null, 'undefined', undefined]) {
  for (let key in payload) {
    const value = payload[key];

    if (value && value.constructor === {}.constructor) {
      if (isEmpty(value)) {
        delete payload[key];
      } else {
        cleanParams(value, params);
      }
    } else if (hasValue(payload, key, params)) {
      delete payload[key];
    }
  }

  return payload;
}
