import axios from 'axios';

export default function PublicFetch(baseURL) {
  this.publicFetch = axios.create({baseURL, 'Content-Type': 'application/json;charset=utf-8'});

  this.publicFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const status = error && error.response ? error.response.status : 0;
      if ([401, 403].includes(status)) {
        return Promise.reject(error);
      }

      if (status >= 400 && status <= 500 && error.response.data) {
        return Promise.reject(error.response.data.data);
      }

      return Promise.reject(error);
    }
  );

  return this.publicFetch;
}
