import React from 'react';
import Button from 'react-bootstrap/Button';

const Btn = ({wrapperClass, variant, disabled, label, onClick, ...props}) => {
  return (
    <div className={wrapperClass}>
      <Button
        {...props}
        variant={variant}
        disabled={disabled}
        className="rounded-pill px-4 mt-2 button"
        onClick={onClick}
      >
        {label}
      </Button>
    </div>
  );
};

Btn.defaultProps = {
  wrapperClass: '',
  variant: 'primary',
  disabled: false
};

export default Btn;
